import React, { useState, Suspense } from "react";
import { FaInfoCircle, FaTimesCircle  } from "react-icons/fa"; // Import Info Icon
import HelpModal from "../components/HelpModal"; // Import the HelpModal component
import { useAnchorProvider } from "../utils/anchorProvider";
import WhatWeDo from "../sections/WhatWeDo"; 

// Lazy loading the section components
const BondSection = React.lazy(() => import("../sections/Bond"));
const FrenBondSection = React.lazy(() => import("../sections/FrenBond"));
const UnbondSection = React.lazy(() => import("../sections/Unbond"));
const SecureSection = React.lazy(() => import("../sections/Secure"));
const ReleaseSection = React.lazy(() => import("../sections/Release"));
const QuickSection = React.lazy(() => import("../sections/QuickBond"));

const AdminSecton = React.lazy(() => import("../sections/Admin"));

// Define a union type for valid section keys
type SectionKey = 'bond' | 'frenBond' | 'unbond' | 'secure' | 'release' | 'quickBond';

const PageWithSections = ({ rpcUrl, heliusApiKey, cluster }: { rpcUrl: string, heliusApiKey:string, cluster:string }) => {
  const [refreshKeys, setRefreshKeys] = useState({
    bond: 0,
    frenBond: 0,
    unbond: 0,
    secure: 0,
    release: 0,
    quickBond: 0,
  });

  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [helpContent, setHelpContent] = useState<SectionKey | null>(null); // Set type to SectionKey or null
  const [isWhatWeDoOpen, setIsWhatWeDoOpen] = useState(false); // State for expanding What We Do section

  const provider = useAnchorProvider(rpcUrl);

  // Open modal with help text
  const openHelpModal = (content: SectionKey) => { // Ensure content is SectionKey type
    setHelpContent(content);
    setHelpModalOpen(true);
  };

  // Close modal
  const closeHelpModal = () => {
    setHelpModalOpen(false);
    setHelpContent(null);
  };

  const toggleWhatWeDo = () => {
    setIsWhatWeDoOpen(!isWhatWeDoOpen);
  };

  return (
    <div className="container mx-auto p-4 pt-16 space-y-2 mb-8 bg-black"> {/* Added space-y-8 for section spacing */}
      <div className="flex flex-col items-center space-y-16 w-full"> {/* Adjusted the spacing */}

      {/* What We Do Section */}
      <div className="w-full mb-8">
      <button
        onClick={toggleWhatWeDo}
        className="bg-[#512da8] text-white py-2 px-4 rounded hover:bg-gray-600 py-2 px-6 rounded-md w-full text-center flex items-center justify-center space-x-2"
      >
        {isWhatWeDoOpen ? (
          <>
            <FaTimesCircle /> <span>I'M DONE READING, HIDE IT</span> <FaTimesCircle />
          </>
        ) : (
          <>
            <FaInfoCircle /> <span>LEARN WHAT WE DO</span> <FaInfoCircle />
          </>
        )}
      </button>

        {/* Smoothly expand/collapse What We Do content */}
        <div
          className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
            isWhatWeDoOpen ? 'max-h-screen' : 'max-h-0'
          }`}
        >

          <Suspense fallback={<div>Loading What We Do...</div>}>
            {isWhatWeDoOpen && <WhatWeDo />} {/* Render only if open */}
          </Suspense>
        </div>
      </div>
        {/* BOND Section */}
        <SectionHeader
          title="BOND"
          onHelpClick={() => openHelpModal('bond')} // Pass 'bond' key
        />
        <SectionWrapper key={`bond-${refreshKeys.bond}`}>
        <div className="text-center p-4 bg-[#090222] text-gray-400">👉 This feature <b>does not support cNFTs</b>. Do not bond tokens or SOL to <b>cNFTs</b>. You will <b>not</b> be able to unbond once bonded! 👈</div>
          <Suspense fallback={<div>Loading Bond Section...</div>}>
            <BondSection rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} provider={provider} cluster={cluster} />
          </Suspense>
        </SectionWrapper>

        {/* FREN BOND Section */}
        <SectionHeader
          title="FREN BOND"
          onHelpClick={() => openHelpModal('frenBond')} // Pass 'frenBond' key
        />
        <SectionWrapper key={`frenBond-${refreshKeys.frenBond}`}>
        <div className="text-center p-4 bg-[#090222] text-gray-400">👉 This feature <b>does not support cNFTs</b>. Do not bond tokens or SOL to <b>cNFTs</b>. You will <b>not</b> be able to unbond once bonded! 👈</div>
          <Suspense fallback={<div>Loading Fren Bond Section...</div>}>
            <FrenBondSection rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} provider={provider} cluster={cluster}  />
          </Suspense>
        </SectionWrapper>


        {/* QUICK BOND Section */}
        <SectionHeader
          title="QUICK BOND"
          onHelpClick={() => openHelpModal('quickBond')} // Pass 'quickBond' key
        />
        <SectionWrapper key={`quickBond-${refreshKeys.bond}`}>
        <div className="text-center p-4 bg-[#090222] text-gray-400">👉 This feature <b>does not support cNFTs</b>. Do not bond tokens or SOL to <b>cNFTs</b>. You will <b>not</b> be able to unbond once bonded! 👈</div>
          <Suspense fallback={<div>Loading Quick Bond Section...</div>}>
            <QuickSection rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} provider={provider} cluster={cluster}  />
          </Suspense>
        </SectionWrapper>



        {/* UNBOND Section */}
        <SectionHeader
          title="UNBOND"
          onHelpClick={() => openHelpModal('unbond')} // Pass 'unbond' key
        />
        <SectionWrapper key={`unbond-${refreshKeys.unbond}`}>
          <Suspense fallback={<div>Loading Unbond Section...</div>}>
            <UnbondSection rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} provider={provider} cluster={cluster}  />
          </Suspense>
        </SectionWrapper>

        {/* SECURE NFT Section */}
        <SectionHeader
          title="SECURE NFT"
          onHelpClick={() => openHelpModal('secure')} // Pass 'secure' key
        />
        <SectionWrapper key={`secure-${refreshKeys.secure}`}>
        <div className="text-center p-4 bg-[#090222] text-gray-400">👉 This feature <b>does not support cNFTs, pNFTs or SPL-404 NFTs</b> 👈</div>
          <Suspense fallback={<div>Loading Secure Section...</div>}>
            <SecureSection rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} provider={provider} cluster={cluster} />
          </Suspense>
        </SectionWrapper>

        {/* RELEASE NFT Section */}
        <SectionHeader
          title="RELEASE NFT"
          onHelpClick={() => openHelpModal('release')} // Pass 'release' key
        />
        <SectionWrapper key={`release-${refreshKeys.release}`}>
        <div className="text-center p-4 bg-[#090222] text-gray-400">👉 This feature <b>does not support cNFTs, pNFTs or SPL-404 NFTs</b> 👈</div>
          <Suspense fallback={<div>Loading Release Section...</div>}>
            <ReleaseSection rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} provider={provider} cluster={cluster}  />
          </Suspense>
        </SectionWrapper>

        {/* Conditionally render the private section based on environment variable */}
        {process.env.REACT_APP_PRIVATE_SECTION === 'true' && (
          <SectionWrapper key={`admin-${refreshKeys.release}`}>
            <Suspense fallback={<div>Loading Section...</div>}>
              <AdminSecton 
                rpcUrl={rpcUrl} 
                heliusApiKey={heliusApiKey} 
                provider={provider} 
                cluster={cluster}  
              />
            </Suspense>
          </SectionWrapper>
        )}

        {/* Help Modal */}
        <HelpModal isOpen={helpModalOpen} onClose={closeHelpModal} section={helpContent as SectionKey} />
      </div>
    </div>
  );
};

export default PageWithSections;

// SectionWrapper component to manage smooth sliding transitions and borders
interface SectionWrapperProps {
  children: React.ReactNode;
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({ children }) => {
  return (
    <div className="transition-all duration-1000 ease-in-out overflow-hidden rounded-lg w-full mb-8">
      <div className="transition-opacity duration-700 ease-in-out opacity-100">
        {children}
      </div>
    </div>
  );
};

// Section Header with info icon
interface SectionHeaderProps {
  title: string;
  onHelpClick: () => void;
}
//bg-[#1ACEDE]
const SectionHeader: React.FC<SectionHeaderProps> = ({ title, onHelpClick }) => {
  return (
    <div className="flex items-center w-full">
      <div className="flex-grow border-t border-[#25124D]"></div>
      <div className="bg-[#F66B1C] text-black py-4 px-6 rounded-full w-48 text-center mx-4 font-bold shadow-md relative">
        {title}
        <button onClick={onHelpClick} className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-800">
          <FaInfoCircle />
        </button>
      </div>
      <div className="flex-grow border-t border-[#25124D]"></div>
    </div>
  );
};
