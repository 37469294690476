import React from "react";
import { FaTimes } from "react-icons/fa";

// Define a union type for the valid section keys
type SectionKey = 'bond' | 'frenBond' | 'unbond' | 'secure' | 'release'| 'quickBond';

// Help content stored in the modal file
const helpTexts: Record<SectionKey, string> = {
  bond: `
  <h3 class="text-lg font-bold text-center mb-4">BOND</h3>
  <p>🔒 <strong>Bond your assets with style!</strong> 🔒</p>
   <br> 
  <p>Secure your SOL or tokens by bonding them to your NFTs.</p>
   <br> 
  <p>Once bonded, only you, the NFT owner can unlock them. Keep your Solana, and tokens secure, even from drainers!</p> 
  <br>    
  
  <p>Whether you're using Flex Bonds to keep your assets safe or to add extra value to your NFTs, bonding is the way to go!</p>
  <br>
  <p><em>Pro-tip: Keep your passkey safe, do not share with anyone. Your passkey is your golden ticket!</em></p>

  <h4 class="font-bold mt-4">Steps to Bond:</h4>
  <ol class="list-decimal list-inside">
    <li>Log into your wallet, click "FETCH NFTS" to see all the NFTs you currently own.</li>
    <li>Select the NFT you want to bond SOL or tokens with. Set up your <strong>unbreakable</strong> passkey. Remember, this passkey <strong>cannot</strong> be recovered, and it's not stored anywhere! Keep it secret, keep it safe.</li>
    <li>Choose how much SOL or how many tokens you want to bond to your chosen NFT. Click "<strong>BOND</strong>", and boom, you're done!</li>
  </ol>
  <br>
  <p><em>Pro-tip: if you can't find your NFT, try turning off the spam filter.</em></p>
 <br>
  `,

  frenBond: `
  <h3 class="text-lg font-bold text-center mb-4">FREN BOND</h3>
  <p>👯 <strong>Bond with your Fren’s NFT!</strong> 👯</p>
  <p>Want to bond SOL or tokens to a fren’s NFT?</p>
  
  <p>Fren Bond lets you securely transfer assets straight to your fren's NFT. You bond it, they get it. It’s all done privately! Let your fren flex too. Perfect for gifts, rewards, surprises, or secret deals. 🤫</p>

  <h4 class="font-bold mt-4">Steps to Fren Bond:</h4>
  <ol class="list-decimal list-inside">
    <li>Type in your fren's wallet address, and click "<strong>FETCH FREN'S NFTS</strong>".</li>
    <li>Pick an NFT, and set up a passkey that you’ll share with your fren. The passkey is their golden ticket, so don't lose it! Share the passkey with your fren via secure means.</li>
    <li>Enter the amount you want to send, and hit the "<strong>BOND</strong>" button. Your fren will thank you later!</li>
  </ol>
  <br>
  <p><em>Pro-tip: if you can't find your fren's NFT, try turning off the spam filter.</em></p>
  <br>
  `,

  unbond: `
  <h3 class="text-lg font-bold text-center mb-4">UNBOND</h3>
  <p>🔓 <strong>Unbond and cash in!</strong> 🔓</p>
  <p>Got assets bonded to your NFT, and are ready to cash out so you can by that next dip?</p>
  <br>
  <p>Unbonding is easy, and hassle-free. Just remember to have your passkey ready—because no passkey, no unbonding! 💼</p>

  <h4 class="font-bold mt-4">Steps to Unbond:</h4>
  <ol class="list-decimal list-inside">
    <li>Connect your wallet and click "<strong>FETCH NFTS</strong>" to  load up your NFTs.</li>
    <li>Select the NFT you have previously bonded, and enter the passkey used during bonding. Always remember, the passkey is <strong>irreplaceable</strong>, and if you lose it, there's no recovery!</li>
    <li>Click "<strong>UNBOND</strong>", and retrieve your assets. Now go buy that dip! 💸</li>
  </ol>
  <br>
  <p><em>A 3.33% fee is charged from the total amount unbonded.</p></em>
  <br>
  <p><em>Pro-tip: if you can't find all your NFTs, try turning off the spam filter.</em></p>
  <br>
  `,

  secure: `
  <h3 class="text-lg font-bold text-center mb-4">SECURE NFT</h3>
  <p>🛡️ <strong>Secure it like Fort Knox!</strong> 🛡️</p>
  <p>Lock down your NFT so nobody but you can move it. Add a passkey, and now you’ve got a digital safe for your NFT.  🔒</p>

  <h4 class="font-bold mt-4">Steps to Secure:</h4>
  <ol class="list-decimal list-inside">
    <li>Select the NFT you want to protect.</li>
    <li>Choose a <strong>rock-solid</strong> passkey that’ll lock it up tight. No one can unlock it without that passkey!</li>
    <li>Confirm, and your NFT is now locked. Forever safe and sound from bad actors.</li>
  </ol>
  <br>
  `,

  release: `
  <h3 class="text-lg font-bold text-center mb-4">RELEASE NFT</h3>
  <p>🦅 <strong>Ready to set your NFT free?</strong> 🦅</p>
  <br>
  <p>When the time comes to release your secured NFT, it's as simple as entering your passkey, and letting it fly. Just don’t forget, no passkey means no freedom for your NFT! 🕊️</p>

  <h4 class="font-bold mt-4">Steps to Release:</h4>
  <ol class="list-decimal list-inside">
    <li>Fetch your secured NFTs by entering your wallet address, and passkey.</li>
    <li>Select the NFT you want to release, and input that <strong>all-important passkey</strong>. Keep in mind, this passkey is not stored anywhere, so don’t lose it!</li>
    <li>Hit "<strong>RELEASE</strong>" and let your NFT soar!</li>
  </ol>
  <br>
  `,

  quickBond: `
  <h3 class="text-lg font-bold text-center mb-4">QUICK BOND</h3>
  <p>🔒 <strong>Already know the NFT you want to bond to?</strong> 🔒</p>
   <br> 
  <p>Just enter the token mint address of the NFT you want to bond to. It can be your own NFT or a friend's NFT. Go through the bonding steps, and your assets are now safely bonded to the NFTyou chose —whether it's yours or someone else's. 🎉
  </p>
   <br> 

   <p>
   Remember, <strong>if you're bonding to someone else's NFT</strong>, make sure to share the passkey with them. Only the person with the passkey can unlock the bonded assets, and they also need to be the owner of the NFT to unbond any assets bonded to it.
   </p>
  <br>    
 
  <h4 class="font-bold mt-4">Steps to Bond:</h4>
  <ol class="list-decimal list-inside">
    <li>Connect your wallet.</li>
    <li>Enter the mint address of the NFT you want to bond SOL or tokens with.</li>
    <li>Click "<strong>BOND SOL</strong>" or "<strong>BOND TOKENS</strong>" depending on what you want to bond.</li>
    <li>Enter your <strong>unbreakable passkey</strong> and the amount of SOL or tokens you wish to bond. </li>
    <li>Click "<strong>BOND</strong>", and your assets will be securely bonded to the NFT.</li>
  </ol>
  <br>
  `,
};

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
  section: SectionKey; // Use SectionKey as type for section prop
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose, section }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
        >
          <FaTimes />
        </button>
        <div
          className="text-gray-700 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: helpTexts[section] }}
        />
      </div>
    </div>
  );
};

export default HelpModal;
