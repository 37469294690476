import { AnchorProvider, Program, web3} from "@coral-xyz/anchor";
import { useWallet, useConnection, AnchorWallet } from "@solana/wallet-adapter-react";
import idl from '../idl/flex_bonds.json'; // Import the IDL (as JSON, no need to cast)
import { FlexBonds } from '../types/flex_bonds'; // Import the generated types


// This is a helper function to get the FlexBonds Anchor program.
export function getFlexBondsProgram(provider: AnchorProvider) {

    // Instantiate the program and log the ID
    const program = new Program(idl as FlexBonds, provider);

    return program;
}

// Hook to create an AnchorProvider with a specific RPC URL
export function useAnchorProvider(rpcUrl: string) {
    const wallet = useWallet();
    const connection = new web3.Connection(rpcUrl, "confirmed");

    const provider = new AnchorProvider(connection, wallet as AnchorWallet, {
        commitment: "confirmed",
    });

    return provider;
}
