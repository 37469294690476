import React, { FC, useMemo, ReactNode } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';

// Default styles
require('@solana/wallet-adapter-react-ui/styles.css');

// Modify Wallet component to accept children as a prop
interface WalletProps {
    children: ReactNode;
}

export const Wallet: FC<WalletProps> = ({ children }) => {
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(() => [], [network]);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children} {/* Render the children passed to the Wallet component */}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
