import React from "react";
import { FaGithub, FaTwitter, FaExternalLinkAlt } from "react-icons/fa"; 
import logo from "../flex-logo.png";
import solanaLogo from "../solanaLogo.png"

const Footer = () => {
  return (
    <footer className="bg-[#16062E] text-white p-4 text-center mt-auto">
      <div className="container mx-auto">

        {/* Links Section */}
        <div className="flex justify-center space-x-6 mt-4 pb-3">
          <a
            href="https://github.com/fomo404" 
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:text-gray-800"
          >
            <FaGithub size={20} />
            <span>GitHub</span>
          </a>
          <a
            href="https://twitter.com/flex_bonds" 
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:text-gray-800"
          >
            <FaTwitter size={20} />
            <span>X</span>
          </a>

          <a
            href="https://pitch.com/v/flex-bonds-ge9uji/ec72e93d-4586-47e3-807b-39b75248803c" 
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:text-gray-800"
          >
            <FaExternalLinkAlt size={20} />
            <span>Pitch Deck</span>
          </a>

        </div>

      </div>
      


      <p>Copyright &copy; 2024 - flexbonds.io</p>

      <div className="pt-6">built on</div>
      <div className="flex items-center justify-center pt-3 pb-4">
        
        <img src={solanaLogo} alt="Logo" className="max-h-8" />
      </div>


    </footer>

  );

};

export default Footer;
