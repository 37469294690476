import React, { useState } from 'react';
import { Buffer } from 'buffer';
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar";
import PageWithSections from './components/PagesWithSections';
import Footer from './components/Footer';
import { Wallet } from './components/Wallet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admin  from './sections/Admin';

window.Buffer = Buffer; // Make Buffer globally available

const RPC_URL = process.env.REACT_APP_RPC_URL_MAINNET!.toString();
const HELIUS_API_KEY = process.env.REACT_APP_HELIUS_API_KEY!.toString();
const CLUSTER = process.env.REACT_APP_HELIUS_CLIENT_CLUSTER!.toString();

function App() {
  const [rpcUrl, setRpcUrl] = useState(RPC_URL);
  const [heliusApiKey, setHeliusApiKey] = useState(HELIUS_API_KEY);

  document.title = "flexbonds.io"
  return (
    <div>
    <Wallet>
      <Router>
        <div className="flex flex-col min-h-screen bg-black">
          <Navbar setRpcUrl={setRpcUrl} setHeliusApiKey = {setHeliusApiKey} />
          <main className="flex-grow">
            <PageWithSections rpcUrl={rpcUrl} heliusApiKey={heliusApiKey} cluster={CLUSTER} /> {/* Pass the rpcUrl to PageWithSections */}
          </main>
          <Footer />
        </div>
      </Router>
    </Wallet>

      {/* Add ToastContainer */}
      <ToastContainer
        position="bottom-right"       // Position of the toast
        autoClose={5000}              // Auto close after 5 seconds
        hideProgressBar={false}       // Show progress bar
        newestOnTop={false}           // Newest toast on top
        closeOnClick
        rtl={false}                   // Left-to-right
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"           
      />
    </div>

  );
}

export default App;
