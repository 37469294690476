const WhatWeDo = () => {
    return (
      <div className="text-white p-4 bg-gray-800 rounded-md">
        <h2 className="text-xl font-bold mb-2 text-center pb-4">🚀 What We Do 🚀</h2>
        <p className="pb-4">
          Thought your NFTs are just art? Not so! With <strong>Flex Bonds</strong>, your NFTs can become your own private digital safe 🔒. Bond your SOL or tokens, add a passkey, and boom 💥—you've got a secret vault! 
        </p>
        <p className="pb-4">
          Perfect for keeping your treasures invisible to the prying eyes 👀 of bad actors. Hackers and wallet drainers often target wallets they know hold a certain amount of assets. What if you could make your exact holdings unknown to them, adding an extra layer of security to your assets? With Flex Bonds, it’s easy to do! 🔐
        </p>
        <p className="pb-4">
          Once your assets, such as SOL or tokens, are bonded to your NFT (or multiple NFTs), they are no longer visible in your wallet balance to strangers 🕵️‍♂️. On top of this, you can also lock your NFTs to your wallet using our <strong>Secure NFT</strong> feature, making it impossible for hackers and drainers to move your secured NFTs.
        </p>
        <p className="pb-4">
          Additionally, Flex Bonds is a great way to add value 💎 to NFT collections and individual NFTs. Collection devs can bond various tokens to their NFTs in amounts of their choice. This can even be gamified 🎮, for example, by bonding different amounts based on traits.
        </p>
        <p className="pb-4">
          And there's more! With Flex Bonds’ <strong>Fren Bond</strong> feature, you can bond SOL or tokens straight to third-party wallets 🎁—making it perfect for gifts, giveaways, rewards, surprises, and more.
        </p>
        <p className="pb-4">
          Bonding, securing, and releasing NFTs with Flex Bonds is free 🆓. Unbonding SOL and tokens incurs a modest 3.33% fee 💰.
        </p>
        <p className="mt-4  font-bold flex justify-center space-x-2">
            <span>⬇️</span>
            <span>⬇️</span>
            <span>⬇️</span>
        </p>
        <p className="mt-4 pb-4 text-xl font-bold ">
          <p>TL;DR: Flex Bonds transforms NFTs into private safes. 🔐 Bond SOL or tokens to your NFTs, add a passkey, and protect assets from prying eyes, drainers, and hackers. Secure your NFTs, add value to NFT collections, or send tokens to others with Fren Bond. Bonding is free; unbonding incurs a 3.33% fee.</p>
        </p>
      </div>
    );
  };
  
  export default WhatWeDo;
  